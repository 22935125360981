console.clear();

import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {Text} from 'troika-three-text'
import { CineonToneMapping, DoubleSide, LinearEncoding, ReinhardToneMapping, sRGBEncoding } from 'three'
import { gsap } from 'gsap'

/**
 * Base
 */

let sceneReady = false
let firstChoice = true
let box1Opened = false
let box2Opened = false
let box3Opened = false

const prizes = ['£5', '£10', '£20']
let box1,boxLid1, box2, boxLid2, box3, boxLid3, box1Group, box2Group, box3Group


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
* Loaders
*/
// Texture loader
const textureLoader = new THREE.TextureLoader()


// GLTF loader
const gltfLoader = new GLTFLoader()


// MODEL

gltfLoader.load(
'/models/box_test.glb',
(gltf) => {
  
  scene.add(gltf.scene)

  // define box1
  box1 = gltf.scene.children.find((child) => child.name === 'box');
  boxLid1 = gltf.scene.children.find((child) => child.name === 'box_lid');
  box1.material = bakedMaterial
  boxLid1.material = bakedMaterial
  box1Group = new THREE.Group()
  box1Group.add( box1, boxLid1)
  scene.add(box1Group)
  box1Group.position.set(0, 0, 2)
  box1Group.name = "group1"

  
  // clone box 1 to make box2 then group the box and lid so can be moved and rotated together
  box2 = box1.clone()
  boxLid2 = boxLid1.clone()
  box2Group = new THREE.Group()
  box2Group.add( box2, boxLid2)
  scene.add(box2Group)
  box2Group.position.set(-2, 0, -2)
 
  box2Group.name = "group2"

  // clone box 1 to make box3 then group the box and lid so can be moved and rotated together
  box3 = box1.clone()
  boxLid3 = boxLid1.clone()
  box3Group = new THREE.Group()
  box3Group.add( box3, boxLid3)
  scene.add(box3Group)
  box3Group.position.set(2, 0, -2)
  
  box3Group.name = "group3"
  
  sceneReady = true
  }
)

// TEXTURES

const bakedTexture = textureLoader.load('/textures/Box_branded_bake_denoised.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = sRGBEncoding


// MATERIALS
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })
//const textMaterial = new THREE.MeshBasicMaterial({ side: DoubleSide})
// IMPORTANT FOR COLOUR MATCHING!
//purpleMaterial.color.setHex( 0x4C12A1 ).convertSRGBToLinear()
const textMaterial = new THREE.MeshBasicMaterial({ side:DoubleSide})
textMaterial.color.setHex( 0xE5006D).convertSRGBToLinear()


// RANDOM PRIZE
// Very basic - should be replaced
//const rndInt = Math.floor(Math.random() * 3)
let rndInt, randomPrize
console.log(prizes)

function takeItem(){
  rndInt = Math.floor(Math.random() * (prizes.length))
  randomPrize = prizes.splice(rndInt, 1)
  console.log(randomPrize)
  console.log(prizes)
  return randomPrize
}



// TEXT

const prizeText1 = new Text()
scene.add(prizeText1)

prizeText1.text = takeItem()
prizeText1.font = '/fonts/CurrysSans-Headline.woff'
prizeText1.fontSize = 0.8
prizeText1.anchorX = 'center'
prizeText1.position.y += 1.5
prizeText1.material = textMaterial
prizeText1.sdfGlyphSize = 128
prizeText1.position.set(0, 1, 2)


const prizeText2 = new Text()
scene.add(prizeText2)

prizeText2.text = takeItem()
prizeText2.font = '/fonts/CurrysSans-Headline.woff'
prizeText2.fontSize = 0.8
prizeText2.anchorX = 'center'
prizeText2.position.y += 1.5
prizeText2.material = textMaterial
prizeText2.sdfGlyphSize = 128
prizeText2.position.set(-2, 1, -2)
prizeText2.rotation.y = Math.PI

const prizeText3 = new Text()
scene.add(prizeText3)

prizeText3.text = takeItem()
prizeText3.font = '/fonts/CurrysSans-Headline.woff'
prizeText3.fontSize = 0.8
prizeText3.anchorX = 'center'
prizeText3.position.y += 1.5
prizeText3.material = textMaterial
prizeText3.sdfGlyphSize = 128
prizeText3.position.set(2, 1, -2)
prizeText3.rotation.y = Math.PI


/**
* Sizes
*/
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () =>
{
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
* Camera
*/
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 6
camera.position.z = -16
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target = new THREE.Vector3(0,1,0)
controls.enableDamping = true
//controls.autoRotate = true
controls.autoRotateSpeed = 4.0
controls.minDistance = 10
controls.maxDistance = 24
controls.minPolarAngle = Math.PI / 4
controls.maxPolarAngle = Math.PI / 2

/**
* Renderer
*/
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = sRGBEncoding
//renderer.toneMapping = CineonToneMapping

//RAYCASTER

const raycaster = new THREE.Raycaster()
const mouse = new THREE.Vector2()
const confettiCoords = new THREE.Vector2()
 
window.addEventListener('pointerdown', function( event ){
  mouse.x = event.clientX / sizes.width * 2 - 1;
  mouse.y = - (event.clientY / sizes.height) * 2 + 1;
  //console.log(mouse)

  confettiCoords.x = event.clientX / sizes.width
  confettiCoords.y = event.clientY / sizes.height
  //console.log(confettiCoords)


    // Cast a ray
  raycaster.setFromCamera(mouse, camera);

  const objectsToTest = [box1, box2, box3]
  const intersects = raycaster.intersectObjects(objectsToTest)
  
  if (intersects.length > 0){
    const vector = new THREE.Vector3(intersects[0].object.parent.position.x, intersects[0].object.parent.position.y, intersects[0].object.parent.position.z)
    console.log(vector)
    vector.project(camera)
   
    /* vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio));
    vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio)); */
    console.log(vector)
    switch(intersects[0].object.parent.name) {
      case 'group1':
        if (box1Opened === false){
          openBox(boxLid1, prizeText1, confettiCoords)
          box1Opened = true
        }
        break;
      case 'group2':
        if (box2Opened === false){
          openBox(boxLid2, prizeText2, confettiCoords)
          box2Opened = true
        }
        break;
      case 'group3':
        if (box3Opened === false){
          openBox(boxLid3, prizeText3, confettiCoords)
          box3Opened = true
        }
        break;
      default:
    } 
  }
  
 })

// ANIMATION - OPEN BOX

function openBox(whichLid, whichPrize, mouseCoords) {
  var boxAnimations = gsap.timeline({
    defaults: {
      ease: "power3.inOut"
    }
  })

  boxAnimations.to(whichLid.rotation, {
    duration: 5,
    x: -Math.PI *1.3,
    onComplete: function(){
    }
  })

  boxAnimations.to(whichPrize.position, {
    duration:1,
    y: whichPrize.position.y + 2.5,
    onStart: function(){
      if (firstChoice === true){
        setTimeout(function(){

          var count = 300;
          var defaults = {
            origin: {
              x: confettiCoords.x,
              y: confettiCoords.y
            }
          };
  
          function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
              particleCount: Math.floor(count * particleRatio)
            }));
          }
  
          fire(0.25, {
            spread: 26,
            startVelocity: 55,
          });
          fire(0.2, {
            spread: 60,
          });
          fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
          });
          fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
          });
          fire(0.1, {
            spread: 120,
            startVelocity: 45,
          });
        }, 400)

        firstChoice = false
      }
      
    }
  },2.5)
  
}

/**
* Tick function
*/
const clock = new THREE.Clock()

const tick = () =>
{
  const elapsedTime = clock.getElapsedTime()

  // Update controls
  controls.update()

  if (sceneReady){
    box1Group.lookAt(camera.position.x, 1, camera.position.z)
    box2Group.lookAt(camera.position.x, 1, camera.position.z)
    box3Group.lookAt(camera.position.x, 1, camera.position.z)
    prizeText1.lookAt(camera.position)
    prizeText2.lookAt(camera.position)
    prizeText3.lookAt(camera.position)
  }
  

  // Render
  renderer.render(scene, camera)

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}

tick()